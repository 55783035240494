var g_dev = false;

// window vars
var g_windowHeight = $(window).height();
var g_windowWidth = $(window).width();
$(window).resize(function () {
	g_windowHeight = $(window).height();
	g_windowWidth = $(window).width();
});

// scroll vars
var g_scroll = $(window).scrollTop();
$(window).scroll(function () {
	g_scroll = $(window).scrollTop();
});

// loadJs
function loadJs(filename) {
	var l = document.createElement('script');
	l.type = 'text/javascript';
	l.src = filename;
	var h = document.getElementsByTagName('head')[0];
	h.parentNode.insertBefore(l, h);
}

// Unsorted scripts
$(document).ready(function () {

	// pre-toggle
	$('pre').each(function () {
		$(this).prepend('<div class="pre-toggle"><i class="icon icon-chevron-down"></i></div>');
	});
	$('.pre-toggle').on('click', function () {
		$(this).closest('pre').toggleClass('active');
	});

	// dismiss alert
	$(".alert .icon-cross").on('click', function (e) {
		e.preventDefault();
		$(this).closest(".alert").remove();
	});

	// social media share buttons
	$('.share').on('click', function (e) {
		e.preventDefault();
		var target = $(this).attr('href').toString();
		window.open(target, 'popup', 'width=500, height=300, top=20, left=20');
	});

	// newsletter singup form
	$('#newsletter-form').on('submit', function (e) {
		e.preventDefault();

		var name = $('input[name="name"]', $(this)).val();
		var mail = $('input[name="mail"]', $(this)).val();

		$('.formElement').removeClass('error');
		$('#newsletter-form-header').find(".alert").remove();

		$.ajax({
			type: 'POST',
			url: 'ajax/mailchimp',
			data: {name: name, mail: mail}
		}).done(function (feedback) {
			var alertClass;
			if (feedback.hasOwnProperty('error') && feedback.error === 1) {
				$('.formElement').addClass('warning');
				alertClass = 'error';
			} else {
				$('#newsletter-form').hide();
				alertClass = 'success';
			}
			$('#newsletter-form-header').append('<div class="alert alert-' + alertClass + '"><p>' + feedback.message + '</p></div>');
		});
	});

});


// slickslider
// options for different instances
// $(document).ready(function () {
// 	if ($('.slider').length > 0) {
// 		$('.slider').slick({
// 			dotsClass: 'slick-dots inside-dots',
// 			autoplaySpeed: 5000,
// 			pauseOnHover: false,
// 			slidesToScroll: 1,
// 			slidesToShow: 1,
// 			draggable: true,
// 			autoplay: true,
// 			infinite: true,
// 			arrows: true,
// 			dots: false,
// 			speed: 500
// 		});
// 	}
// });

$(document).ready(function () {
	if ($('.slider-media').length > 0) {
		$('.slider-media').slick({
			// dotsClass: 'slick-dots',
			autoplaySpeed: 5000,
			pauseOnHover: false,
			slidesToScroll: 1,
			slidesToShow: 3,
			draggable: true,
			autoplay: true,
			infinite: true,
			arrows: true,
			dots: false,
			speed: 500
		});
	}
});

// google maps
// only for single map usage
var mapsLoader = null;
var latlong = false;
var address = '';
var scriptLocation = '';

if ($("#google_maps").length > 0) {
	address = $("#google_maps").data('address');
	scriptLocation = $("#google_maps").data('script');

	$(function () {
		tryToInitializeGmaps();
		$(window).on('resize scroll', function () {
			tryToInitializeGmaps();
		});
	});

	function tryToInitializeGmaps() {
		if ($('#google_maps_wrapper').isInViewport()) {
			if (mapsLoader === null) {
				lazyLoadGoogleMap();
			}
		}
	}

	function initializeGmaps() {
		var geocoder = new google.maps.Geocoder();
		geocoder.geocode({'address': address}, function (results, status) {
			if (status === google.maps.GeocoderStatus.OK) {
				latlong = results[0].geometry.location;

				var mapsOptions = {
					zoom: 12,
					center: latlong,
					scrollwheel: false,
					disableDefaultUI: true,
					panControl: false,
					zoomControl: true,
					zoomControlOptions: {
						style: google.maps.ZoomControlStyle.SMALL,
						position: google.maps.ControlPosition.TOP_LEFT
					},

					mapTypeControl: false,
					streetViewControl: false,
					mapTypeId: google.maps.MapTypeId.ROADMAP
				};

				var map = new google.maps.Map(
					document.getElementById("google_maps"),
					mapsOptions
				);

				var marker = new google.maps.Marker({
					icon: BASE_HREF+'/public/img/marker.png',
					position: latlong,
					map: map
				});

				// pan to the right
				if ($(".google_maps_info").css('maxWidth') !== 'none') {
					var offset = ($(".google_maps_info").width() / 2) + 30;
					/* 30 pixels for margin */
					map.panBy(offset, 0);
				}

			} else {
				alert("Geocode was not successful for the following reason: " + status);
			}
		});
	}

	function lazyLoadGoogleMap() {
		mapsLoader = $.getScript(scriptLocation)
			.done(function () {
				initializeGmaps();
			})
			.fail(function (jqxhr, settings, ex) {
				alert("Could not load Google Map script");
			});
	}
}
